import React, { useContext } from "react";

import type { Question } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { getDecorationProperties } from "../../../client";
import { Markup } from "./TextResource/Markup";
import { ArticleContext } from "../../../contexts/ArticleContext";

function handleNavigation(target: string) {
  const header = document.querySelector(".header");
  const scrollTarget = document.querySelector(target)?.getBoundingClientRect();

  window.scrollTo({
    top: Math.round(
      (scrollTarget?.y as number) +
        (window.scrollY - (header?.clientHeight as number))
    ),
    behavior: "smooth"
  });
}

export const QuestionResource: ResourceComponent<Question> = ({ resource }) => {
  const { article } = useContext(ArticleContext);

  const total = article.resources.filter(({ type }) => type === "Question");
  const question_index = total.indexOf(resource);

  return (
    <div
      id={`question-${article.article_id}-${question_index}`}
      className="resource resource-question"
      style={getDecorationProperties(resource.decoration)}
    >
      {resource.paragraphs.map((question, i) => (
        <div key={i}>
          <Markup text={question.text} />
        </div>
      ))}

      <div className="resource-question--buttons">
        {question_index > 0 && (
          <a
            onClick={e => {
              e.preventDefault();
              handleNavigation(
                `#question-${article.article_id}-${question_index - 1}`
              );
            }}
            className="btn btn--secondary resource-question-button resource-question-button--prev"
            href={`#question-${article.article_id}-${question_index - 1}`}
          >
            <span>Föregående fråga</span>
          </a>
        )}
        {question_index < total.length - 1 && (
          <a
            onClick={e => {
              e.preventDefault();
              handleNavigation(
                `#question-${article.article_id}-${question_index + 1}`
              );
            }}
            className="btn btn--primary resource-question-button resource-question-button--next"
            href={`#question-${article.article_id}-${question_index + 1}`}
          >
            <span>Nästa fråga</span>
          </a>
        )}
      </div>
    </div>
  );
};
