import React from "react";

import type { NumberFact } from "../../../client/ContentApi/models/numberFact";
import type { ResourceComponent } from "./resourceToComponents";

import { getGroupedStyle } from "../helpers";
import { Markup } from "./TextResource/Markup";

export const NumberFactResource: ResourceComponent<NumberFact> = ({
  resource,
  group
}) => (
  <div className={`resource--numberfact ${getGroupedStyle(group)}`}>
    <h2 className="resource--numberfact-title">{resource.title?.value}</h2>
    {resource?.paragraphs?.map((fact, index) => (
      <div className="resource--numberfact-text" key={index}>
        <Markup text={fact.text} />
      </div>
    ))}
  </div>
);
