import React, { useContext } from "react";

import type { InternalArticle } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { LinkToArticleWithOnlyId } from "../../Link";
import { TrackingContext } from "../../../contexts/TrackingContext";
import { ArticleContext } from "../../../contexts/ArticleContext";
import { getStylePostfix, isSimpleUrl, getGroupedStyle } from "../helpers";
import { OmniMerUrl } from "./common/OmniMerUrl";
import { LinkTitle } from "./common/LinkTitle";
import { getConfig } from "../../../config";

export const InternalArticleResource: ResourceComponent<InternalArticle> = ({
  resource,
  group
}) => {
  const { openInternalLink } = useContext(TrackingContext);
  const { article } = useContext(ArticleContext);
  const { translation } = getConfig();

  const { article_id, link_title, style } = resource;
  const stylePostfix = getStylePostfix(style);

  if (!article_id || !link_title) {
    return null;
  }
  return (
    <div
      className={`resource resource--link resource--link${stylePostfix} u-fadehr--after
       ${getGroupedStyle(group)}`}
    >
      <LinkToArticleWithOnlyId
        articleId={article_id}
        title={link_title}
        className="resource--link-link a a--primary"
        target="_blank"
        onClick={() => {
          openInternalLink(article);
        }}
      >
        {isSimpleUrl(style) ? (
          <OmniMerUrl title={link_title} stylePostfix={stylePostfix} />
        ) : (
          <>
            <LinkTitle
              title={link_title}
              stylePostfix={stylePostfix}
              className="a--primary"
            />
            <span className="resource--link-source a--primary">
              {translation.application_name}
            </span>
          </>
        )}
      </LinkToArticleWithOnlyId>
    </div>
  );
};
