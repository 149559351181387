import React from "react";
import Image from "next/image";

import type { LabeledIcon } from "../../../client/ContentApi/models/labeledIcon";
import type { ResourceComponent } from "./resourceToComponents";

import { getImageSizes } from "../../../helpers";

const sizes = getImageSizes({ web: "20px", tablet: "3vw", mobile: "7vw" });

export const LabeledIconResource: ResourceComponent<LabeledIcon> = ({
  resource,
  decoration
}) => {
  const { text, icon_url, size } = resource;

  return (
    <div className="resource--labeledicon" style={decoration}>
      <Image
        src={icon_url}
        alt="Icon"
        width={size.width}
        height={size.height}
        sizes={sizes}
      />
      <span className="resource--labeledicon-text">{text}</span>
    </div>
  );
};
