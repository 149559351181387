import React from "react";

import type { Subheading } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { getFormatPostfix, getGroupedStyle } from "../helpers";

export const SubheadingResource: ResourceComponent<Subheading> = ({
  resource,
  decoration,
  group,
  article
}) => {
  const formatPostfix = getFormatPostfix(article?.format);
  return (
    <h2
      className={`
        resource 
        resource--subheading 
        resource--subheading${formatPostfix} 
        ${
          resource.is_centered_on_large_device
            ? "resource--subheading--centered"
            : ""
        }
        ${getGroupedStyle(group)}
      `}
      style={decoration}
    >
      {resource.text.value}
    </h2>
  );
};
