import React, { useContext } from "react";
import { useRouter } from "next/router";
import Image from "next/image";

import type { ModelMap } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import {
  getFormatPostfix,
  getGroupedStyle,
  getHorizontalPaddingClass
} from "../helpers";
import { getArticleDisplaySize } from "./helpers/article-size";
import { gMapLink, staticMapSrc } from "./helpers/gmap";
import { getImageSizes } from "../../../helpers";

const sizes = getImageSizes({ web: "668px", tablet: "100vw" });

const MapImage = ({ imgSrc, sizes }: { imgSrc: string; sizes: string }) => (
  <Image
    className="resource-img"
    src={imgSrc}
    alt="karta"
    sizes={sizes}
    width={0}
    height={0}
    unoptimized={true}
  />
);

export const ModelMapResource: ResourceComponent<ModelMap> = ({
  resource,
  isOpen = false,
  group
}) => {
  const router = useRouter();

  const { article } = useContext(ArticleContext);
  const displaySize = getArticleDisplaySize(article);

  const mapProportions =
    router.pathname === "/article" || article.teaser_layout === "Large"
      ? "2x1"
      : "1x1";

  const mapSrc = staticMapSrc(
    {
      width: 640,
      height: mapProportions === "1x1" ? 640 : 320
    },
    resource
  );

  const googleMapsLink = gMapLink(resource);
  const formatPostfix = getFormatPostfix(article.format);

  return (
    <div
      className={`resource resource--image resource--map resource--image--${displaySize} resource--image--${displaySize}${formatPostfix} resource--gmap
      ${getGroupedStyle(group)}
      ${getHorizontalPaddingClass(resource.horizontal_padding)}`}
    >
      {isOpen ? (
        <a href={googleMapsLink} rel="noopener noreferrer" target="_blank">
          <MapImage imgSrc={mapSrc} sizes={sizes} />
        </a>
      ) : (
        <MapImage imgSrc={mapSrc} sizes={sizes} />
      )}
    </div>
  );
};
