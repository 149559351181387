import React from "react";

import type { Quote } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { getFormatPostfix, getGroupedStyle } from "../helpers";
import { Markup } from "./TextResource/Markup";

export const QuoteResource: ResourceComponent<Quote> = ({
  resource,
  article,
  decoration,
  group
}) => {
  const formatPostfix = getFormatPostfix(article?.format);

  return (
    <div
      className={`resource--quote ${getGroupedStyle(group)}`}
      style={decoration}
    >
      {resource.paragraphs.map((quote, index) => (
        <div
          className={`resource--quote-large resource--quote-large${formatPostfix}`}
          key={index}
        >
          <Markup text={quote.text} />
        </div>
      ))}
      <div
        className={`resource--quote-author resource--quote-author${formatPostfix}`}
      >
        {resource.author.value}
      </div>
    </div>
  );
};
