import React from "react";

import type { PremiumInformationBox } from "../../../client/";
import type { ResourceComponent } from "./resourceToComponents";

import { getConfig } from "../../../config";

export const PremiumInformationBoxResource: ResourceComponent<
  PremiumInformationBox
> = ({ resource }: { resource: PremiumInformationBox }) => {
  const { NEXT_PUBLIC_MER } = getConfig();

  if (!NEXT_PUBLIC_MER) {
    return null;
  }

  return (
    <div className="omnikey-informationbox">
      <div className="omnikey-informationbox--content">
        <h2 className="omnikey-informationbox--title">{resource.title}</h2>
        <p className="omnikey-informationbox--body">{resource.text}</p>
        <a
          href="https://om.omni.se/omni-mer?utm_content=omni%20key%20box"
          className="btn btn--primary btn--full omnikey-informationbox--button"
          target="_blank"
          rel="noreferrer"
        >
          {resource.button}
        </a>
      </div>
    </div>
  );
};
