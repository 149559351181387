import Link from "next/link";
import React, { useContext } from "react";

import type { ArticleFormat, StoryVignette, Topic } from "../../../client";
import type { ResourceComponent } from "./resourceToComponents";

import { ArticleContext } from "../../../contexts/ArticleContext";
import { omnify } from "../../../lib/slugification";
import { LinkToCategory, LinkToTopic } from "../../Link";
import { StarBox } from "../../StarBox";
import {
  getFormatPostfix,
  isOmniMerFormat,
  isRegularSponsored
} from "../helpers";
import { getArticleDisplaySize } from "./helpers/article-size";

const Supertag = ({ topic }: { topic: Topic }) => {
  return (
    <Link
      className="resource--vignette-supertag"
      href={`/t/${omnify(topic.title)}/${topic.topic_id}`}
    >
      {topic.title}
    </Link>
  );
};

const TopicTitle = ({
  title,
  isNativeAd,
  format
}: {
  title: string;
  isNativeAd: string | undefined;
  format: ArticleFormat;
}) => (
  <span
    className={`resource--vignette-topic${`${getFormatPostfix(format)}`} ${
      isNativeAd ? "resource--vignette-topic--sponsored" : ""
    }
    `}
  >
    {title}
  </span>
);

const Suffix = ({
  suffix = "",
  suffix_separator = "",
  is_premium = false
}: {
  suffix?: string;
  suffix_separator?: string;
  is_premium?: boolean;
}) => (
  <span className="resource--vignette-prefix">
    {suffix_separator}
    {suffix}
  </span>
);

export const StoryVignetteResource: ResourceComponent<StoryVignette> = ({
  resource,
  decoration
}) => {
  const { article } = useContext(ArticleContext);

  const { title, supertag, id, ref, suffix, suffix_separator } = resource;

  if (article?.teaser_layout === "Flash") {
    return null;
  }

  const displaySize = getArticleDisplaySize(article);
  const sizeClass = `resource--vignette--${displaySize}`;
  const isNativeAd = article.sponsor;

  const categoryLink = ref === "category" ? article?.category : null;

  const topicLink = article.story
    ? article.story
    : ref === "topic" && id
    ? { topic_id: id, title }
    : null;

  const shouldAddSuffix =
    isOmniMerFormat(article.format) || isRegularSponsored(article);

  const topicTitleProps = {
    title,
    isNativeAd,
    format: article.format
  };

  const formatPostfix = getFormatPostfix(article.format);

  return (
    <div
      className={`resource resource--vignette-container resource--vignette-container${formatPostfix}`}
      style={decoration}
    >
      <div className={`resource--vignette ${sizeClass} fs--smaller`}>
        {supertag && !suffix && <Supertag topic={supertag} />}
        {categoryLink ? (
          <LinkToCategory category={categoryLink}>
            <TopicTitle {...topicTitleProps} />
          </LinkToCategory>
        ) : topicLink ? (
          <LinkToTopic topic={topicLink}>
            <TopicTitle {...topicTitleProps} />
          </LinkToTopic>
        ) : (
          <TopicTitle {...topicTitleProps} />
        )}
        {shouldAddSuffix && (
          <Suffix
            suffix={suffix}
            suffix_separator={suffix_separator}
            is_premium={article.is_premium}
          />
        )}
      </div>

      {article.story && !article.sponsor && <StarBox topic={article.story} />}
    </div>
  );
};
