import React from "react";

import type { OriginByline } from "../../../client/ContentApi/models/originByline";
import type { ResourceComponent } from "./resourceToComponents";

import { Markup } from "./TextResource/Markup";

export const OriginBylineResource: ResourceComponent<OriginByline> = ({
  resource,
  decoration
}) => {
  const { paragraphs } = resource;
  return (
    <div className="resource--origin" style={decoration}>
      {paragraphs.map((p, i) => (
        <p key={i}>
          <Markup text={p.text} />
        </p>
      ))}
    </div>
  );
};
